<template>
  <q-page>
    <div class="section boxed header">
      <div class="row items-center justify-between q-py-lg">
          <router-link to="/"><img alt="Calcola la superficie commerciale con un click" src="images/logo_areaplan.png" style="height: 100px; max-width: 80vw; object-fit: contain"></router-link>
          <q-btn flat :label="$t('Accedi/registrati')" class="col-auto text-weight-bold" icon-right="account_circle" to="/auth/login"></q-btn>
      </div>
    </div>
    <div class="section boxed q-pb-xl">
      <div class="row items-center">
        <div class="col-md-6 col-sm-12">
          <h1 class="text-h3 text-primary subtitle" style="line-height: 1.3">
            Il software in cloud, per calcolare la <span class="text-accent text-weight-bold">superficie commerciale</span> di un immobile in automatico
          </h1>
          <h5 style="width: 50%">
            funziona anche con planimetrie <span class="text-weight-bold">non in scala</span>
          </h5>
          <q-btn color="accent" text-color="white" size="lg" class="q-px-lg text-bold" to="/auth/signup">{{ $t('generals.try_for_free') }}</q-btn>
        </div>
        <div class="col-md-6 col-sm-12 q-pa-lg">
          <img title="calcolo-superficie-commerciale" alt="calcolare la superficie commerciale delle planimetrie di immobili" src="/images/calcolo-superficie-commerciale.gif" class="full-width q-px-xl">
        </div>
      </div>
    </div>
    <div class="section boxed gradient">
      <div class="text-primary">
        <h3 class="inverse-gradient flex q-px-md q-my-none q-py-lg text-weight-bold" style="display: inline-block;">{{ $t('Come funziona') }}</h3>
        <div class="row items-center justify-center full-width q-py-xl"  :class="{'no-wrap': $q.screen.gt.sm}">
          <div class="rectangle inner-number column" :style="boxStyle">
            <div class="q-px-lg">
              <div class="text-accent text-center text-h5 text-weight-bold q-my-sm">1</div>
              <img src="images/icons/upload.png" style="width: 70px; height: 60px; object-fit: contain" />
              <div :class="boxCaptionClass">{{ $t('home.captions.1') }}</div>
            </div>
            <div class="text-grey-7 q-mb-md q-px-xl text-subtitle1">
              {{ $t('formati supportati') }}<br />.jpg - .png - .pdf
            </div>
          </div>
          <div class="rectangle inner-number column" :style="boxStyle">
            <div class="q-px-lg">
              <div class="text-accent text-center text-h5 text-weight-bold q-my-sm">2</div>
              <img src="images/icons/perimeter.png" style="width: 70px; height: 60px; object-fit: contain" />
              <div :class="boxCaptionClass">{{ $t('home.captions.2') }}</div>
            </div>
            <div class="text-grey-7 q-mb-md q-px-xl text-subtitle1">
              {{ $t('imposta i valori di percentuale e disegna il perimetro degli ambienti') }}
            </div>
          </div>
          <div class="rectangle inner-number column" :style="boxStyle">
            <div class="q-px-lg">
              <div class="text-accent text-center text-h5 text-weight-bold q-my-sm">3</div>
              <img src="images/icons/scale.png" style="width: 70px; height: 60px; object-fit: contain" />
              <div :class="boxCaptionClass">{{ $t('home.captions.3') }}</div>
            </div>
            <div class="text-grey-7 q-mb-md q-px-lg text-subtitle1">
                {{ $t('ti basta avere un\'unica misura corretta della tua planimetria per calcolare l\'intera superficie') }}
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex column text-center justify-center" style="margin-bottom: 130px">
      <div class="full-width flex justify-center">
        <img src="images/icons/done.png" style="width: 50px; height: 50px" />
      </div>
      <h4 class="text-weight-bolder q-ma-none">{{ $t('fatto!') }}</h4>
      <h5 class="q-ma-none">{{ $t('in 3 semplici mosse') }}</h5>
      <h5 class="q-ma-none">{{ $t('calcoli la superficie commerciale delle tue planimetrie') }}</h5>
    </div>
    <div class="flex column text-primary full-width items-center gradient q-my-xl">
      <div class="bg-primary text-white text-h5 text-weight-bold q-px-lg q-py-md q-mt-xs q-my-lg" style="border-radius: 5px;">{{ $t('GUARDA LA DEMO') }}</div>
      <div class="full-width q-mt-xl q-mb-xl video-container">
        <div class="rwd-video">
          <iframe src="https://www.youtube.com/embed/8vMCOQoXc2Y" width="560" height="315" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <div class="section boxed gradient">
      <div class="text-primary">
        <h2 class="text-h3 inverse-gradient flex q-px-md q-my-none q-py-lg text-weight-bold" style="display: inline-block;">{{ $t('Lo strumento perfetto per l\'agenzia immobiliare') }}</h2>
        <div class="text-grey-7 q-mt-md q-px-md">
          <h6 class="q-my-md">
            {{ $t('AREAPLAN è uno strumento utilizzabile direttamente online, pensato per ottenere una rapida misurazione delle planimetrie per una corretta valutazione dell’immobile.')}}
          </h6>
          <h6 class="q-my-md">
            {{ $t('AREAPLAN permette in maniera semplice ed efficace, tramite semplici passaggi, la misurazione di aree direttamente da immagini e foto nei formati JPEG, PDF e PNG. Infine consente di scaricarle in formato PDF sul proprio PC o di stamparle direttamente dall\'applicativo.')}}
          </h6>
        </div>
      </div>
    </div>
    <div class="flex justify-center" style="margin-top: 150px; margin-bottom: 100px">
      <div class="bordered relative-position">
        <q-btn
          color="accent"
          text-color="white"
          :size="$q.platform.is.mobile ? 'md' : 'lg'"
          class="q-px-lg text-bold absolute cta-button"
          to="/auth/signup"
        >
          <span style="white-space: nowrap">{{ $t('INIZIA LA PROVA GRATUITA') }}</span>
        </q-btn>
        <div class="q-pa-xl text-center text-grey-7">
          <h6 class="q-my-none">
            {{ $t('prova AREAPLAN gratuitamente per 15 giorni usando tutte le funzionalità del sistema.')}}
          </h6>
          <h6 class="q-my-none">
            {{ $t('Allo scadere del periodo di prova potrai decidere se sottoscrivere l’abbonamento annuale al costo di 100 € + iva')}}
          </h6>
          <h4 class="text-primary text-center q-my-sm text-weight-bold">{{ $t('Gratis per 15 giorni') }}</h4>
        </div>
      </div>
      <div class="text-grey-7 text-h6 text-center q-mt-xl full-width">
        Misurazione facilitata<br />
        Puoi stampare e modificare ogni planimetria salvata<br />
        Puoi attribuire diverse percentuali di valore per il calcolo della superficie commerciale<br />
      </div>
    </div>
    <div class="section boxed gradient">
      <div>
        <h3 class="inverse-gradient flex q-px-md q-my-none q-py-lg text-weight-bold">{{ $t('Domande frequenti')}}</h3>
        <div class="flex justify-center">
          <q-card style="margin-top: 50px; margin-bottom: 100px; width: 1280px;">
            <div v-for="(question, index) in questions" :key="index">
              <q-expansion-item
                :label="question.text"
                group="faq"
              >
              <template slot="header">
                <h2 class="q-py-md q-px-md q-ma-none text-h6 full-width">{{ question.text }}</h2>
              </template>
              <q-card>
                <q-card-section class="q-px-xl q-py-lg text-subtitle1">
                  {{ question.answer }}
                </q-card-section>
              </q-card>
            </q-expansion-item>
            <q-separator></q-separator>
            </div>
          </q-card>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
export default {
  name: 'Home',
  computed: {
    boxStyle () {
      return {
        width: this.$q.screen.lt.lg ? '350px' : '390px',
        height: this.$q.screen.lt.lg ? '350px' : '390px',
        margin: this.$q.screen.lt.lg ? '15px' : '18px'
      }
    },
    boxCaptionClass () {
      return this.$q.screen.lt.lg ? 'caption q-px-md text-h5' : 'caption q-px-md text-h4'
    }
  },
  data () {
    return {
      questions: [
        {
          text: 'Come fa il sistema a calcolare la superficie commerciale esatta di una planimetria se non conosco la scala esatta?',
          answer: 'AreaPlan riesce ad effettuare il calcolo della superficie commerciale in modo corretto tramite l’indicazione di almeno una misura reale, ti basta sapere la misura esatta di una parete, di una porta, di un balcone o semplicemente di due punti qualsiasi e una volta inserito questo valore nel marcatore di scala, questa viene presa come riferimento per impostare la giusta proporzione di tutte le aree da te disegnate. Se sei in possesso di una planimetria catastale devi riuscire ad individuare almeno 2 punti sulla scala millimetrica ed inserire il valore corrispondente in “metri” sul marcatore di scala.'
        }, {
          text: 'Se sulla planimetria non trovo la scala esatta e non conosco nessuno misura di riferimento, come posso fare?',
          answer: 'In questo caso è impossibile effettuare la misura, è per questo motivo che consigliamo di rilevare (in via preventiva) almeno una misura di una parete quando si è sul posto.'
        },
        {
          text: 'Che tipo di file deve avere la planimetria, posso inserire più planimetrie?',
          answer: 'Il nostro misuratore di planimetrie accetta file in JPEG, PDF, PNG. il sistema può caricare più planimetrie contemporaneamente e per ogni planimetria si può impostare una scala diversa. Il calcolo della superficie commerciale finale farà la somma di tutte le planimetrie inserite.'
        },
        {
          text: 'Si possono ricavare anche le misure interne delle stanze e la superficie calpestabile?',
          answer: 'Si, una volta impostato un valore nel marcatore di scala, puoi misurare la lunghezza di tutte le pareti, tramite lo strumento, “misura la distanza tra due punti”. Per quanto riguarda la superficie calpestabile ti basta disegnare il poligono all’interno di ogni stanza, il sistema farà la somma di tutte le aree da te disegnate.'
        },
        {
          text: 'È possibile fare una stampa da dare al proprietario? Si possono salvare le misurazioni fatte?',
          answer: 'Si, è possibile stampare il risultato della superficie catastale e commerciale, su ogni foglio sarà presente anche il timbro di AREAPLAN che comprova che il rilevamento è stato effettuato con uno strumento professionale e che non è stato fatto a mano, dove ci potrebbe essere stato qualche margine di errore. Inoltre puoi salvare le planimetrie con la misurazione appena fatta per tornarci sopra in un secondo momento.'
        },
        {
          text: 'È possibile impostare una percentuale personalizzata diversa da quella che vedo nel pannello strumenti?',
          answer: 'No, la percentuale da applicare per la misurazione dell’area deve corrispondere a una di quelle già presenti nel pannello strumenti. Nel crearle abbiamo preso in considerazione quelle standard e che vengono utilizzate nella maggior parte dei casi. Nel caso in cui pensiate che manca una percentuale di uso comune, siete pregati di comunicarcelo e insieme valuteremo se aggiungerla al pannello strumenti.'
        },
        {
          text: 'Ma la superficie catastale presente nella visura catastale non è già quella commerciale?',
          answer: 'No, non è detto che la metratura presente nella visura catastale coincida con quella commerciale, sei tu come agente immobiliare che sai come come devono essere considerate le aree commerciali di un immobile in base agli usi e costumi della tua località, per esempio quando hai un terrazzo o un giardino molto grande, potresti ritenere giusto applicare una percentuale più consona, stessa cosa vale per le parti comuni, c’è chi non le considera e c’è chi le considera in una minima parte e in una percentuale che è molto a discrezione del rilevatore.'
        },
        {
          text: 'Ma se i mq commerciali mi sono già stati comunicati dal proprietario dell’immobile?',
          answer: 'Mai fidarsi di quello che viene detto dal proprietario, potrebbe essere una misura approssimativa o addirittura gonfiata, è sempre buona cosa per un agente immobiliare verificare la reale superficie commerciale, che può anche essere di supporto nel fare una giusta valutazione di mercato dell’immobile, nel caso in cui lo si vuole mettere in vendita al giusto prezzo.'
        },
        {
          text: 'È necessario scaricare un software?',
          answer: 'Non è necessario effettuare il download di alcun software; il nostro strumento è basato sul cloud e si può accedere ad esso da qualsiasi computer tramite un account e una password.'
        },
        {
          text: 'L’applicazione può essere utilizzata da più utenti?',
          answer: 'Si, il nostro strumento non è destinato ad essere utilizzato da un solo utente, ma può essere utilizzato da tutti i membri di un’agenzia contemporaneamente. Il nostro obiettivo è garantire che tutti i collaboratori abbiano accesso al servizio.'
        },
        {
          text: 'C’è una limitazione al numero di misurazioni o i progetti sono illimitati?',
          answer: 'Le misurazioni delle planimetrie sono senza restrizioni, non ci sono limiti in termini di numero di progetti, quindi è possibile effettuare tutte le misurazioni desiderate e archiviare tutti i progetti che si desidera.'
        },
        {
          text: 'Quanto costa il software? C’è la possibilità di provarlo gratuitamente?',
          answer: 'Si, è possibile provarlo gratuitamente per 15 giorni. Per poterlo testare, è sufficiente creare un account direttamente dalla piattaforma. Al termine del periodo di prova, lo strumento si bloccherà automaticamente. Se si desidera continuare ad utilizzarlo, sarà necessario sottoscrivere un abbonamento annuale al costo di 100 euro più IVA. Ogni anno, in occasione del rinnovo, verrà inviata un’email di avviso. Nel caso in cui non si voglia rinnovare l’abbonamento, sarà sufficiente accedere all’account e disattivare il rinnovo.'
        },
        {
          text: 'Per quale motivo dovrei usare uno strumento per controllare i mq commerciali?',
          answer: 'Perchè per eseguire una valutazione corretta di un immobile, è consigliabile assicurarsi delle sue dimensioni effettive, è importante considerare quanto influisca la superficie abitabile rispetto agli spazi accessori, come balconi, terrazzi e giardini, così come il ruolo che può svolgere un sottotetto o una cantina se collegati direttamente all’abitazione. Per valutare correttamente il valore dell’immobile, è fondamentale conoscere con precisione la metratura esatta di tutte queste aree.'
        },
        {
          text: 'Quanto tempo serve per fare una misurazione e un stampa?',
          answer: 'Dopo aver acquisito familiarità con lo strumento, bastano pochissimi secondi, anche solo 7, per ottenere la misurazione completa. In questo modo, sarai in grado di aggiungere a tutti i tuoi report di valutazione una dimostrazione grafica della misurazione e del lavoro svolto. Questa visualizzazione sarà ancora più coinvolgente grazie all’uso di una grafica semplice ed intuitiva.'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  .gradient {
    background: rgb(231,232,233);
    background: linear-gradient(180deg, rgba(231,232,233,1) 0%, rgba(255,255,255,1) 100%);
  }
  .inverse-gradient {
    background: rgb(231,232,233);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%,rgba(231,232,233,1) 100%);
  }
  .rectangle {
    background-color: white;
    text-align: center;
    width: 400px;
    margin: 20px;
    position: relative;
    padding: 10px;
    .caption {
      position: absolute;
      left: 0;
      transform: translate(0, -50%);
      top: 50%;
      margin: auto 20px;
    }
    .text-subtitle1 {
      line-height: 1.5;
    }
  }
  .inner-number {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #757575 ;
    border-radius: 5px;
    height: 400px;
  }
  .bordered {
    border: 1px solid #757575 ;
    border-radius: 5px;
  }
  .cta-button {
    text-align: center;
    transform: translate(-50%, -50%);
    left: 50%;
  }
  .section.boxed {
    display: flex;
    > div {
      width: 90vw;
      margin: auto;
    }
  }
  .video-container {
    max-width: 800px;
    .rwd-video {
      height: 0;
      overflow: hidden;
      padding-bottom: 56.25%;
      padding-top: 30px;
      position: relative;
      iframe, object, embed {
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
      }
    }
  }
</style>
